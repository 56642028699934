@font-face {
font-family: '__graphik_639b95';
src: url(https://todoist.b-cdn.net/_next/static/media/ef4deda2fe4d8408-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__graphik_639b95';
src: url(https://todoist.b-cdn.net/_next/static/media/5281648b6f880411-s.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__graphik_639b95';
src: url(https://todoist.b-cdn.net/_next/static/media/f5a0076dcc331c24-s.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__graphik_Fallback_639b95';src: local("Arial");ascent-override: 75.32%;descent-override: 16.76%;line-gap-override: 0.00%;size-adjust: 108.61%
}.__className_639b95 {font-family: '__graphik_639b95', '__graphik_Fallback_639b95', Helvetica Neue, Helvetica, Arial, sans-serif
}.__variable_639b95 {--next-font-family-sans: '__graphik_639b95', '__graphik_Fallback_639b95', Helvetica Neue, Helvetica, Arial, sans-serif
}

.app_appContainer__TEWLy {
    display: flex;
    flex-direction: column;
    min-block-size: 100vh;
    min-block-size: 100dvh;
    margin-inline: auto;
}

    .app_appContainer__TEWLy *::-moz-selection {
        background: #ffa;
    }

    .app_appContainer__TEWLy *::selection {
        background: #ffa;
    }

.app_pageBackground__s0KeE {
    background-color: var(--page-background, var(--composition-background-neutral, #fdf4eb));
}

